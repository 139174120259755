import { useState, createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

const ConnectionContext = createContext({});

export const useConnection = () => useContext(ConnectionContext);

export const withConnection = (Component) => (props) => {
    const { connected } = useConnection();
    return <Component {...props} connected={connected} />
}

const ConnectionProvider = ({ children }) => {
    const [connected, setConnected] = useState(navigator && navigator.onLine);
    const toggleInternetAccess = () => setConnected(navigator && navigator.onLine);

    useEffect(() => {
        // console.log("ConnectionProvider useEffect");
        window.addEventListener('online', toggleInternetAccess);
        window.addEventListener('offline', toggleInternetAccess);

        return () => {
            // console.log("ConnectionProvider useEffect unmount");
            window.removeEventListener('online', toggleInternetAccess);
            window.removeEventListener('offline', toggleInternetAccess);
        }
    }, []);

    return (
        <ConnectionContext.Provider value={{ connected }}>
            {children}
        </ConnectionContext.Provider>
    )
}

export default ConnectionProvider;