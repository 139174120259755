import EndpointService from '../Service/EndpointService';
import { fromJS } from 'immutable';
import { LOAD_SINGLE_USER, UPDATE_SINGLE_USER } from '../Reducers/Users';

// /freelancerportal/api/currentfreelancer

export function getCurrentUser() {

    return dispatch => {

        return new Promise((resolve, reject) => {

            dispatch({ type: LOAD_SINGLE_USER });

            EndpointService.get('/uscportal/api/currentuser').then((response) => {
                console.log("single user response");
                console.log(response.data)
                
                if(response.data && typeof response.data !== 'string') {
                    
                    dispatch({
                        type: UPDATE_SINGLE_USER,
                        single: fromJS(response.data)
                    })

                } 

            }).catch(() => {

            })


        })


    }


}
