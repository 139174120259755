import React, { Component } from 'react';
import './App.scss';
import { HashRouter as Router } from 'react-router-dom';
import Content from './Content';
import { connect } from 'react-redux';
import { getCurrentFreelancer, getFreelancers } from './Actions/Freelancers';
import { getClients } from './Actions/Clients';
import { getCurrentUser } from './Actions/Users';
import { getPortalUsers } from './Actions/PortalUsers';
import { withConnection } from './Context/Connection';



import MainMenu from './MainMenu';
import MenuToggle from './MenuToggle';

String.prototype.convertToNumber = function() {
    return Number(this.replace(/\./g,',').replace(/,([0-9]{0,2})$/, '.$1').replace(/\,/g, ''))
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    getCurrentFreelancer: () => dispatch(getCurrentFreelancer()),
    getClients: () => dispatch(getClients()),
    getCurrentUser: () => dispatch(getCurrentUser()),
    getPortalUsers: () => dispatch(getPortalUsers()),
    getFreelancers: () => dispatch(getFreelancers())
})


class App extends Component {



    componentDidMount() {

        let { getCurrentFreelancer, getClients, getCurrentUser, getPortalUsers, getFreelancers } = this.props;
        getCurrentFreelancer && getCurrentFreelancer();
        getClients && getClients();
        getFreelancers && getFreelancers();
        getCurrentUser && getCurrentUser();
        getPortalUsers && getPortalUsers();

    }
    // account
    // accounts-outline
    // border-color


    // account Mijn planning
    // accounts-outline Cliënten / Planning
    // border-color Uren accorderen

    render() {
        
        let { connected } = this.props;

        return (
            <Router >
                <div className="portal">

                    <div className="header">

                    <MenuToggle />
                    <h2>Zorgconnect light</h2>

                    </div>
                    { !connected && <div className="bg-red-400 p-2">
                        Let op! Er is momenteel geen internetverbinding, de functies van Zorgconnect werken alleen met internetverbinding.
                    </div> }

                    <div className="container">

                        <MainMenu  />


                        <Content />
                    </div>
                </div>
            </Router>
        );
    }
}


 
export default connect(mapStateToProps, mapDispatchToProps)(withConnection(App));
