import { createContext, useState, useEffect, useRef } from 'react';
import EndpointService from '../../Service/EndpointService';
import { set } from 'immutable';

const PWAContext = createContext({});

const isPWACheck = () => {
    const mqStandAlone = '(display-mode: standalone)';
    if(
        navigator?.standalone ||
        window.matchMedia(mqStandAlone).matches
    ) {
        return true;
    }
    return false;
}

async function registerDevice() {
    let registerResponse = await EndpointService.post('/api/register-device', { portal: "zl" });
    return registerResponse.data;

}

export default PWAContext;

export function PWAProvider({ children }) {

    const [isPWA, setIsPWA] = useState(isPWACheck());
    const [isPWARegistered, setIsPWARegistered] = useState(localStorage.getItem('isPWARegisteredTzl') || false);
    const [isPWAInstallable, setIsPWAInstallable] = useState(false);
    const promptRef = useRef(null);

    const showPrompt = async () => {
        try {
            if(promptRef.current) {
                promptRef.current.prompt();
                let userChoice = await promptRef.current.userChoice;
                if (userChoice?.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                    
                }
                return true;
            }
        } catch(err) {
            return false;
        }
    
    }

    const refreshEndpointPWAStatus = async () => {
        if(isPWACheck()) {
            EndpointService.setIsPWA(true);
        }
    }

    const refreshPWAStatus = () => {
        if(
            isPWACheck()
        ) {
            EndpointService.setIsPWA(true);
            setIsPWA(true);
        }
    }

    useEffect(() => {
        refreshPWAStatus();

        const onBeforeInstallPrompt = (e) => {
            e.preventDefault();
            promptRef.current = e;
            setIsPWAInstallable(true);
        }

        window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
        return () => {
            window.removeEventListener(onBeforeInstallPrompt)
        }

    }, [])

    const registerPWA = async () => {
        refreshEndpointPWAStatus();
        let registration = await registerDevice();
        console.log("registration response", registration)
        if(registration.success) {
            localStorage.setItem('isPWARegisteredTzl', true);
            localStorage.setItem('pwaRegistrationTzl', JSON.stringify({ id: registration.id, token: registration.token}))
        }
    }


    return (
        <PWAContext.Provider value={{
            isPWA,
            isPWAInstallable,
            setIsPWAInstallable,
            isPWARegistered, 
            setIsPWARegistered,
            showPrompt,
            registerPWA
        }}>
            {children}
        </PWAContext.Provider>
    )
}

