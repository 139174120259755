import { LOAD_PROFILEPLANNING, UNLOAD_PROFILEPLANNING, UPDATE_PROFILEPLANNING } from '../Reducers/ProfilePlanning';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import { collection } from '../testdata/profileplanning';



export function loadProfilePlanning(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_PROFILEPLANNING,
            loading: true
        })
        EndpointService.post("/uscportal/api/profileplanning", params).then((response) => {
            
            dispatch({
                type: LOAD_PROFILEPLANNING,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getProfilePlanning(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadProfilePlanning(params));
    }
}

