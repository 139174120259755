import React, { useState, createContext, useEffect, useContext } from 'react';
import axios from 'axios';
import EndpointService from '../../Service/EndpointService';
import PWAContext from '../../PWA/Context/PWAContext';
import { parseUserAgent } from '../../PWA/Utils';

async function logout() {
    let statusResponse = await EndpointService.get('/logout-api');
    return statusResponse.data;
    // return { login, userToken };
}

async function requestApplicationLogout() {
    let statusResponse = await EndpointService.get('/api/logout-application');
    window.location.reload();
    return statusResponse.data;
    // return { login, userToken };
}

async function requestPasskeyReset() {
    let statusResponse = await EndpointService.get('/api/passkey-remove');
    let localStorageKeyAddendum = "";
    let applicationString = EndpointService.getCurrentPWAString();
    if (applicationString) {
        localStorageKeyAddendum = `T${applicationString}`;
    }
    localStorage.removeItem(`lastPasskey${localStorageKeyAddendum}`);
    localStorage.removeItem(`skipPasskeyRegistration${localStorageKeyAddendum}`);
    window.location.reload();
    return statusResponse.data;
    // return { login, userToken };

}

async function requestApplicationReset() {
    let statusResponse = await EndpointService.get('/api/reset-application');

    let localStorageKeyAddendum = "";
    let applicationString = EndpointService.getCurrentPWAString();
    if (applicationString) {
        localStorageKeyAddendum = `T${applicationString}`;
    }

    localStorage.removeItem(`lastPasskey${localStorageKeyAddendum}`);
    localStorage.removeItem(`skipPasskeyRegistration${localStorageKeyAddendum}`);
    localStorage.removeItem(`isPWARegistered${localStorageKeyAddendum}`);
    localStorage.removeItem(`pwaRegistration${localStorageKeyAddendum}`);
    console.log("should have been resetted");
    window.location.reload();
    /*
        Localstorage keys:
            lastPasskeyTzl
            skipPasskeyRegistrationTzl
            lastPasskeyTzl
            isPWARegisteredTzl
            pwaRegistrationTzl
    */


    return statusResponse.data;
    // return { login, userToken };

}

const AuthenticationContext = createContext({});

export function AuthenticationProvider({ children }) {

    const [loginInfo, setLoginInfo] = useState({});
    const pwaContext = useContext(PWAContext);

    const _logout = async () => {
        let statusResponse = await logout();
        setLoginInfo({});
        // refresh window
        window.location.reload();
        return statusResponse;


    }

    const renewLoginStatus = async (loginContext = {}) => {
        let { login, userToken, isPasskeyEnabled, deviceRegistered, redirectUrl } = await checkLoginStatus();
        const { isPWA, isPWARegistered, registerPWA } = pwaContext;

        if(!login && redirectUrl) {
            window.location.href = redirectUrl;
        }
        console.log("renewLoginStatus pwaContext", pwaContext);
        let isDeviceRegistered = deviceRegistered;
        if (userToken?.completeLogin && isPWA && !isPWARegistered) {
            isDeviceRegistered = await registerPWA();
        }
        setLoginInfo((loginInfo) => {
            return {
                ...loginInfo,
                isLoggedIn: login,
                userToken: userToken,
                isPasskeyEnabled: isPasskeyEnabled,
                deviceRegistered: isDeviceRegistered,
                loginContext: loginContext,
                is2FAuthenticated: userToken?.completeLogin
            };
        })


    }

    useEffect(() => {

        let lastLoginInfo = localStorage.getItem('lastPasskeyTzl');
        if (lastLoginInfo) {
            setLoginInfo(
                (loginInfo) => ({
                    ...loginInfo,
                    lastLoginInfo: JSON.parse(lastLoginInfo)
                })
            );


        }


        window.parseUserAgent = parseUserAgent;
        window.listUserAgents = (str) => {

            let userAgents = str.split("\n");
            let unknowns = [];
            userAgents.forEach((ua) => {
                let parsed = parseUserAgent(ua);
                console.log(parsed);
                if (parsed.browserName === 'Unknown' || parsed.deviceType === 'Unknown' || parsed.browserVersion === 'Unknown') {
                    unknowns.push(ua);
                } else {
                    const { browserName, browserVersion, deviceType } = parsed;
                    console.log(`Browser: ${browserName}, Version: ${browserVersion}, Device: ${deviceType}`);
                }
            });

            unknowns.forEach((ua) => {
                console.log(ua);
            });

        }

    }, [])

    return (
        <AuthenticationContext.Provider value={{
            loginInfo,
            setLoginInfo,
            logout: _logout,
            renewLoginStatus: renewLoginStatus, requestApplicationLogout, requestPasskeyReset, requestApplicationReset
        }}>
            {children}
        </AuthenticationContext.Provider>
    )
}


export default AuthenticationContext;




async function checkLoginStatus() {
    let statusResponse = await EndpointService.post('/api/check', {});
    return statusResponse.data;
    // return { login, userToken };
}






