import { LOAD_PORTALUSERS, UNLOAD_PORTALUSERS, UPDATE_PORTALUSERS } from '../Reducers/PortalUsers';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import Immutable, { fromJS } from 'immutable';



export function loadPortalUsers() {
    return (dispatch) => {

        dispatch({
            type: LOAD_PORTALUSERS,
            loading: true
        })
        EndpointService.get(`/uscportal/api/portalusers`).then((response) => {
            
            dispatch({
                type: LOAD_PORTALUSERS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getPortalUsers() {
    return dispatch => {
        // syncing logic
        dispatch(loadPortalUsers());
    }
}

