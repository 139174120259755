import React, { Component } from 'react';
import Calendar from '../Calendar';
import CalendarNavigation from '../Calendar/CalendarNavigation';
import ProfilePlanning from '../../Reducers/ProfilePlanning';
import { List, Map, OrderedMap } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';
import { sendCareMessage } from '../../Actions/Mail';

const mapStateToProps = (state) => ({
    freelancer: state.getIn(['freelancers','single'], Map()),
    planningrequests: state.getIn(['planningrequests', 'collection'], OrderedMap())
});

const mapDispatchToProps = (dispatch) => ({
    sendCareMessage: (mail) => dispatch(sendCareMessage(mail))
});

class SingleClientPlanning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentEvent: undefined,
            planningRequest: Map()
        }
    
    }

    componentDidMount() {
        let { getPlanningRequests } = this.props;
        getPlanningRequests && getPlanningRequests();
        
    }

    render() {
        let { profileplanning, client } = this.props;
        return <div className="card">
            <div className="card-body">
               <CalendarNavigation />
               <Calendar 
                    onEventSelect={this.onEventSelect}
                    events={convertClientPlanningToEvents(profileplanning)}
                />
           </div>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClientPlanning);


const colors = {
    "single-filled": (op = 1) => `rgba(0, 219, 4,${op})`,
    "single-not-filled": (op = 1) => `rgba(253, 255, 0,${op})`
}

function convertClientPlanningToEvents(profileplanning, planningrequests) {
    let events = List();

    const createEventFromRecord = (record, infoRecord, tag = "") => {
        let planningEvents = List();
        let event = Map({ 
            id: tag + infoRecord.get('id').toString(),
            event: infoRecord,
            starttime: record.get('starttime'),
            endtime: record.get('endtime'),
            startTimeIndex: record.get('startTimeIndex'),
            durationIndex: record.get('durationIndex'),
            year: record.get('year'),
            week: record.get('week'),
            dow: record.get('dow')
        })

        let op = infoRecord == record ? 1 : 0.5;
        event = event.set('background', infoRecord.get('freelancerid') ? colors['single-filled'](op) : colors['single-not-filled'](op) );
        event = event.set('label', infoRecord.get('freelancerid', "OPEN") || "OPEN");

        let start = event.get('startTimeIndex');
        let duration = event.get('durationIndex');
        let end = start + duration;
        if(end > 96) {
            let first = event.set('durationIndex', 96 - start);
            let second = event.set('startTimeIndex', 0).set('durationIndex', event.get('durationIndex') - first.get('durationIndex'));
            
            let nextDateInfo = getNextYearWeekDow(event.get('year'), event.get('week'), event.get('dow'));
            second = second.set('year', nextDateInfo.year)
            .set('week', nextDateInfo.week)
            .set('dow', nextDateInfo.dow);
            
            planningEvents = planningEvents.push(first);
            planningEvents = planningEvents.push(second.update('id', x => x + "split" + tag));
        } else {
            planningEvents = planningEvents.push(event);
        }

        return planningEvents;
    }


    profileplanning.forEach((pp) => {


        let convertedEvents = createEventFromRecord(pp, pp);
        events = events.merge(convertedEvents);

        if(pp.getIn(['extra','before'])) {
            let beforeEvent = pp.getIn(['extra','before']);
            let extraStarttime = moment(Number(pp.get('starttime'))).subtract(Number(beforeEvent.get('durationIndex')) * 15, 'minutes').format('x');
            let extraEndtime = pp.get('starttime')
            beforeEvent = beforeEvent.set('starttime', extraStarttime);
            beforeEvent = beforeEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(beforeEvent, pp, 'before');
            events = events.merge(convertedEvents);
        }

        if(pp.getIn(['extra','after'])) {
            let afterEvent = pp.getIn(['extra','after']);
            let extraStarttime = pp.get('endtime');
            let extraEndtime = moment(Number(pp.get('endtime'))).add(Number(afterEvent.get('durationIndex')) * 15, 'minutes').format('x');
            afterEvent = afterEvent.set('starttime', extraStarttime);
            afterEvent = afterEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(afterEvent, pp, 'after');
            events = events.merge(convertedEvents);
        }

    })

    return events;

}

function getNextYearWeekDow(_year, _week, _dow) {
    let date = moment().year(_year).isoWeek(_week).isoWeekday(_dow);
    date.add(1,'d');
    let year = date.year();
    let week = date.isoWeek();
    let dow = date.isoWeekday();
    return {
        year: year,
        week: week,
        dow: dow
    }
}
