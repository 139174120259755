import React, { Component } from 'react';
import './Styles/Home.scss';
import { connect } from 'react-redux';
import { OrderedMap } from 'immutable';
import { generateDisplayName, generateFormalDisplayName } from '../../Utils/Person';
import { NavLink } from 'react-router-dom';

const mapStateToProps = (state) => ({
    clients: state.getIn(['clients','collection']),
    freelancers: state.getIn(['freelancers','collection'])
})

class Home extends Component {


    render() {

        // console.log(clients);

        let { clients, freelancers } = this.props;

        return <div className="card home">

            <div className="card-body">
                <div className="card-header">
                    Clienten
                </div>

                <div className="namelist">
                    <ul>
                        { (clients || OrderedMap()).map((client, id) => {

                            return <li key={id.toString()}>
                                <NavLink to={`/clients/${client.get('id')}`}>
                                    {client.get('id')} - {generateFormalDisplayName(client)}
                                </NavLink>
                            </li>

                        }).toList()}
                    </ul>
                </div>
            </div>

            <div className="card-body">

                <div className="card-header">
                    Freelancers
                </div>

                <div className="namelist">
                    <ul>
                        { (freelancers || OrderedMap()).map((freelancer, id) => {

                            return <li key={id.toString()}>
                                <NavLink to={`/freelancers/${freelancer.get('id')}`}>
                                    {freelancer.get('id')} - {generateFormalDisplayName(freelancer)}
                                </NavLink>
                            </li>

                        }).toList()}
                    </ul>
                </div>

            </div>


        
        </div>

    }

}

export default connect(mapStateToProps)(Home);