import React, { Component, useContext } from 'react';
import AuthenticationContext from './Authentication/Context/AuthenticationContext';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { Menu } from './Styles/Menu';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import { generateDisplayName } from './Utils/Person';
import { toggleMainMenu } from './UIState';


function LogoutButton() {
    
        const { logout } = useContext(AuthenticationContext);
    
        return <a href="#" onClick={logout}>
            <i className="zmdi zmdi-caret-left-circle" />
            Uitloggen
        </a>
    
    
}

const mapStateToProps = (state) => ({
    menuOpened: state.getIn(['ui','mainMenuOpened']),
    user: state.getIn(['users','single'], Map())
})

const mapDispatchToProps = (dispatch) => ({
    toggleMenuOpened: () => dispatch(toggleMainMenu())
})

const apiUrl = window.location.href.match('localhost:30') ? "http://localhost:8080" : ".";

class MainMenu extends Component {

    

    render() {

        let { menuOpened, user, toggleMenuOpened } = this.props;

        return <Menu active={menuOpened} className={(menuOpened ? " open" : " closed")}>

        <h2 className="menu-header">Welkom, {user.get('firstname')}</h2>

        <ul className="inner-menu">
            <li>
                <Link to="/"  onClick={toggleMenuOpened}>
                    <i className="zmdi zmdi-accounts-outline" />
                    Clienten & Freelancers
                </Link>
            </li>
            
            <li>
                <Link to="/who-is-who"  onClick={toggleMenuOpened}>
                    <i className="zmdi zmdi-accounts" />
                    Wie is wie
                </Link>
            </li>
            <li>
                <Link to="/infotheek"  onClick={toggleMenuOpened}>
                    <i className="zmdi zmdi-info" />
                    Infotheek
                </Link>
            </li>
            {/* <li>
                <Link to="/settings"  onClick={toggleMenuOpened}>
                    <i className="zmdi zmdi-settings" />
                    Instellingen
                </Link>
            </li> */}
            <li>
                {/* <a href={`${apiUrl}/logout`}>
                    <i className="zmdi zmdi-caret-left-circle" />
                    Uitloggen
                </a> */}
                <LogoutButton />
            </li>
        </ul>
        
        <PortalUserSelector />

    </Menu>


    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);

function PortalUserSelector() {

    let portalUsers = useSelector(state => state.getIn(['portalusers','collection']));

    console.log("portalUsers from portalUsers", portalUsers);

    if(!portalUsers || portalUsers.size < 2) {
        return null;
    }

    return <React.Fragment>
        <h2 className="menu-header">Portalen</h2>
        <ul className="inner-menu">
            { portalUsers && portalUsers.map((portalUser) => {
                
                let portalType = portalUser.get('usertype') == 'freelancer' ? 'Freelancerportaal' : 'Clientenportaal';
                let portalUrl = portalUser.get('usertype') == 'freelancer' ? '/selectfreelancerportal/' + portalUser.get('id') : '/selectclientportal/' + portalUser.get('id');

                return  <li>
                    <a href={portalUrl}>
                    {portalType} {portalUser.get('gender') == 'M' ? 'Dhr.' : 'Mw.'} {generateDisplayName(portalUser)} 
                    </a>
                </li>

            }).toList()}
        </ul>

    </React.Fragment>

}