import { useEffect, useState, useContext, useRef, Fragment } from 'react';
import PWAContext from './Context/PWAContext';


export default function InstallApp({ onInstall }) {

   
    const { setIsPWAInstallable, isPWAInstallable, showPrompt, isPWA } = useContext(PWAContext);
    const [justInstalled, setJustInstalled] = useState(false);

    const cancelInstall = () => {
        setIsPWAInstallable(false);
    }

    if(!isPWAInstallable || isPWA) {
        return null;
    }

    const startInstall = async () => {
        let result = await onInstall();
        if(result) {
            // setJustInstalled(true);
        }
    }

    return <Fragment>
        { justInstalled && <div className="fixed w-screen h-screen top-0 left-0 bg-[rgb(245,239,224)] flex flex-row items-center justify-center p-4"> 
            <div className="w-72">
                 <img className="w-full" src="https://u-staat-centraal.nl/wp-content/uploads/2020/10/ustaatcentraal_logo-1.svg" />
            </div>
            <div className="p-4 bg-white text-white flex flex-col gap-3 ">

                <p>De app is geinstalleerd. Je kunt nu makkelijker inloggen!</p>
                <button className="p-2 bg-green-800 w-full" onClick={() => window.location.reload()}>Ga verder</button>
            </div>

        </div>}
        <div className="p-2 bg-green-600 text-white flex flex-col gap-3 md:hidden">

            <p>
                Wil je ZorgConnect installeren als een applicatie op je telefoon?
                Als je hiervoor kiest log je voortaan eenvoudig in zoals je gewend bent:
                met gezichtsherkenning, vingerafdruk of code
            </p>
            
            <div className="flex flex-row gap-2">
                <button className="p-2 bg-green-800" onClick={startInstall}>Ja, installeren</button>
                <button className="p-2 bg-red-700" onClick={cancelInstall}>Nee, niet nu</button>
            </div>

        </div>
    </Fragment>

}