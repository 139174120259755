import { LOAD_PORTAL_ADDRESSES, UNLOAD_PORTAL_ADDRESSES, UPDATE_PORTAL_ADDRESSES } from '../Reducers/PortalAddresses';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import Immutable, { fromJS } from 'immutable';



export function loadPortalAddresses(clientid) {
    return (dispatch) => {

        dispatch({
            type: LOAD_PORTAL_ADDRESSES,
            loading: true
        })
        EndpointService.get(`/uscportal/api/portaladdresses/${clientid}`).then((response) => {
            
            dispatch({
                type: LOAD_PORTAL_ADDRESSES,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getPortalAddresses(clientid, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadPortalAddresses(clientid));
    }
}

