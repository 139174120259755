import React, { Component } from 'react';
import './Styles/Calendar.scss';
import { useSwipeable } from 'react-swipeable';
import moment from 'moment';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
moment.locale('nl');

const mapStateToProps = (state) => ({ 
    weekRangeStart: state.getIn(['ui','weekRangeStart']),
    weekRangeEnd: state.getIn(['ui','weekRangeEnd']) 
})

const colors = {
    "single-filled": "#00db04",
    "single-not-filled": "#fdff00"
}

class Calendar extends Component {
    

    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
        this.state = {
            currentDay: 0,
            zoomState: 0
        }
       this.navigateLeft = this.navigateLeft.bind(this);
       this.navigateRight = this.navigateRight.bind(this);
       this.zoomCalendar = this.zoomCalendar.bind(this);
    }
    zoomCalendar() {
        const vw = Math.max(document.documentElement.clientWidth) - 100;
        console.log("zooming calendar")
        if(vw / 900 < 1) {
            this.elementRef.current.style.zoom = vw / 900;
            this.setState({
                zoomState: vw / 900
            })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.zoomCalendar);
        this.zoomCalendar();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.zoomCalendar);
    }
    selectEvent(event) {
        let { onEventSelect } = this.props;
        if(!event.getIn(['event','freelancerid'])) {
            onEventSelect && onEventSelect(event.get('event'));
        }
        
    }

    navigateLeft() {
        console.log("swiped left");
        this.setState(({ currentDay }) => ({ currentDay: (currentDay && currentDay - 1 || 6) % 7 }));
    }

    navigateRight() {
        console.log("swiped right");
        this.setState(({ currentDay }) => ({ currentDay: (currentDay + 1) % 7 }));
    }

   
    // to see whether day should show on mobile
    getActiveClass(className, day) {
        let { currentDay } = this.state;
        return className + (currentDay == day ? " active" : "");
    }

    getNextYearWeekDow(_year, _week, _dow) {
        let date = moment().year(_year).isoWeek(_week).isoWeekday(_dow);
        date.add(1,'d');
        let year = date.year();
        let week = date.isoWeek();
        let dow = date.isoWeekday();
        return {
            year: year,
            week: week,
            dow: dow
        }
    }

    render() {


        let { profileplanning, weekRangeEnd, weekRangeStart, events } = this.props;
        let { zoomState } = this.state;
        let currentWeek = Number(moment(weekRangeStart).isoWeek());

    

        events = events.filter(x => 
            ( x.get('starttime') >= weekRangeStart && x.get('starttime') < weekRangeEnd ) || 
            ( x.get('endtime') > weekRangeStart && x.get('endtime') <= weekRangeEnd )
        );

        events = events.filter(x => Number(x.get('week')) == Number(currentWeek));
        events = events.groupBy(x => x.get('dow'));
    
        let zoomStyle = {};
        if(zoomState) {
            zoomStyle['WebkitTextSizeAdjust'] = Math.round(zoomState * 100) + '%';
        }

        return <div className="calendar" ref={this.elementRef} style={zoomStyle}>
        
            <div className="labels">
            
                <div className="corner">
                    <div className="week-label">
                        
                    </div>
                </div>

                <div className="weekdays">  
                
                    {(() => {
                        let startDate = moment(weekRangeStart);
                        let days = [];
                        let day = 0;
                        while(day < 7) {

                            let dayName = startDate.format('dddd');
                            let dateString = startDate.format('DD-MM-YYYY');
                            startDate.add(1, 'd');
                            days.push( <div key={dateString} className={this.getActiveClass("weekday-label", day)}>
                                <div className="weekday-navigation-left">
                                    <a href="javascript:;" onClick={this.navigateLeft}><i className="zmdi zmdi-chevron-left"></i></a>
                                </div>
                                {dayName}<br />
                                {dateString}
                                <div className="weekday-navigation-right">
                                    <a href="javascript:;" onClick={this.navigateRight}><i className="zmdi zmdi-chevron-right"></i></a>
                                </div>
                            </div>)
                            day++;
                        }

                        return days;

                    })()}

                
                </div>

                <div className="corner">
                    <div className="week-label">
                        
                    </div>
                </div>

            </div>

            <div className="days">
            
                <div className="hour-labels">
                
                    {(() => {
                        let l = [];
                        for(var i = 0; i < 24; i++) {
                            l.push(<div className="hour-label" key={i.toString()}>{i > 9 ? i : "0" + i}:00</div>)
                        }
                        return l
                    })()}

                </div>
                
                <Swipeable 
                    className="weekdays"
                    onSwipedLeft={this.navigateLeft}
                    onSwipedRight={this.navigateRight}>
                
                    {(() => {
                        let d = [];
                        for(var y = 0; y < 7; y++) {

                            let dayEvents = events.get(y + 1, List());
                            let divEvents = List();
                            let alreadyInRange = List();
                            dayEvents.forEach((dayEvent) => {
                                let start = dayEvent.get('startTimeIndex');
                                let duration = dayEvent.get('durationIndex');
                                let end = start + duration;
                                let eventClassName = "event";
                                let style = {
                                    top: (start * 10) || "0" + "px",
                                    height: (duration * 10) + "px",
                                    background: dayEvent.get('background')
                                    // background: dayEvent.get('freelancerid') ? colors['single-filled'] : colors['single-not-filled']
                                }
                                let eventAlreadyInRange = alreadyInRange.some(x => 
                                    (x.get('start') >= start && x.get('start') < end) || 
                                    (start >= x.get('start') &&  end < x.get('end'))
                                );
                                alreadyInRange = alreadyInRange.push(Map({
                                    start: start,
                                    end: end
                                }));
                                if(eventAlreadyInRange) {
                                    eventClassName = eventClassName + " overlap"

                                }
                                if(zoomState) {
                                    style['WebkitTextSizeAdjust'] = Math.round(zoomState * 100) + '%';
                                }
                                divEvents = divEvents.push(<SingleEvent onClick={this.selectEvent.bind(this, dayEvent)} className={eventClassName} style={style} dayEvent={dayEvent} key={dayEvent.get('id').toString()} />)
                            })

                            d.push(<div className={this.getActiveClass("weekday", y)} key={y.toString()}>
                                
                                {(() => {
                                    let l = [];
                                    for(var i = 0; i < 96; i++) {
                                        l.push(<div className="quarter" key={"q" + i}></div>)
                                    }
                                    return l;
                                })()}

                                {divEvents} 

                                
                            </div>)
                        }
                        return d;
                    })()}
                    
                </Swipeable>
                

                <div className="hour-labels">
                    
                    {(() => {
                        let l = [];
                        for(var i = 0; i < 24; i++) {
                            l.push(<div className="hour-label" key={i.toString()}>{i > 9 ? i : "0" + i}:00</div>)
                        }
                        return l
                    })()}

                </div>

            </div>
        
        
        </div>


    }

}

function Swipeable(props) {



    const handlers = useSwipeable({
      onSwipedLeft: () => props.onSwipedLeft(),
      onSwipedRight: () => props.onSwipedRight()
    });
  
    return <div {...handlers} className={props.className} >{props.children}</div>;

}

export default connect(mapStateToProps)(Calendar);


function SingleEvent({ onClick, className, style, dayEvent }) {

    return <div onClick={onClick} className={className} style={style}>
        <div>{dayEvent.get('label')}</div>
    </div>
}