import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OrderedMap, Map, List } from 'immutable';
import { generateDisplayName, generateFormalDisplayName, generateSemiFormalDisplayName } from '../../Utils/Person';
import { getClients } from '../../Actions/Clients';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import Calendar from '../Calendar';
import CalendarNavigation from '../Calendar/CalendarNavigation';
import { NavLink, Switch, Route } from 'react-router-dom';

import './Styles/SingleFreelancer.scss';
import { getPortalAddresses } from '../../Actions/PortalAddresses';
import { setWeekRange } from '../../UIState';
import moment from 'moment';

const apiUrl = window.location.href.match('localhost:30') ? "http://localhost:8080" : "";

const mapStateToProps = (state) => ({
    weekRangeStart: state.getIn(['ui','weekRangeStart']),
    weekRangeEnd: state.getIn(['ui','weekRangeEnd']),
    clients: state.getIn(['clients','collection'], OrderedMap()),
    freelancers: state.getIn(['freelancers','collection'], OrderedMap()),
    profileplanning: state.getIn(['profileplanning','collection'], OrderedMap()),
    freelancer: state.getIn(['freelancers', 'single'], Map()),
    portalAddresses: state.getIn(['portaladdresses','collection'], List()),
})

const mapDispatchToProps = (dispatch) => ({
    getClients: () => dispatch(getClients()),
    getProfilePlanning: (params) => dispatch(getProfilePlanning(params)),
    setWeekRange: (start, end) => dispatch(setWeekRange(start, end)),
    getPortalAddresses: (clientid) => dispatch(getPortalAddresses(clientid))
})

class SingleFreelancer extends Component {

    componentDidMount() {
        let { getClients, getProfilePlanning, getFreelancers } = this.props;
        let { setWeekRange } = this.props;
        console.log("SingleClient componentDidMount");
        console.log("props", this.props);

        let { match: { params: { start, end, freelancerid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        if(
            (start || end) && 
            start != weekRangeStart || end != weekRangeEnd
        ) {
            console.log("setting week range start : start",  start);
            console.log("setting week range start : weekRangeSTart", weekRangeStart );
            console.log("setting week range end : end",  end);
            console.log("setting week range end : weekRangeEnd", weekRangeEnd );
            setWeekRange && setWeekRange(
                Number(start || weekRangeStart), 
                Number(end || weekRangeEnd)
            );
        }
        getProfilePlanning && getProfilePlanning({
            freelancerid: freelancerid
        });
        console.log("componentDidMount SingleClient", this.props);

    }

    componentDidUpdate(prevProps) {

        let { match: { params: { start, end, freelancerid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        let { setWeekRange } = this.props;
        let currentStart = start;
        let currentEnd = end;
        let currentWeekRangeStart = weekRangeStart;
        let currentWeekRangeEnd = weekRangeEnd;


        {
            let { match: { params: { start, end }}, weekRangeStart, weekRangeEnd } = prevProps;
            if(
                (currentStart || currentEnd) &&
                (currentStart != start || currentEnd != end)
                && 
                (currentStart != currentWeekRangeStart || currentEnd != currentWeekRangeEnd)
            ) {
                console.log("setting week range start : currentStart",  currentStart);
            console.log("setting week range start : currentWeekRangeStart", currentWeekRangeStart );
            console.log("setting week range end : currentEnd",  currentEnd);
            console.log("setting week range end : currentWeekRangeEnd", currentWeekRangeEnd );
                setWeekRange && setWeekRange(
                    Number(currentStart || currentWeekRangeStart), 
                    Number(currentEnd || currentWeekRangeEnd)
                );
            }
        }
    }

    render() {

        let { portalAddresses, profileplanning, freelancers, clients,  match: { path, params: { freelancerid, start, end } } } = this.props;

        let freelancer = freelancers.get(freelancerid);

        if(!freelancer) {
            return <data />
        }

        console.log("client", freelancer);



        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));
        profileplanning = profileplanning.filter(x => x.get('freelancerid') == freelancerid);

        console.log("SingleClient props", this.props);

        return <div className="card single-client">
        
            <div className="card-header">
                <h1>{generateDisplayName(freelancer)}</h1>

            </div>


            <div className="card-body client-view">
                <div className="client-view-side">
                    <h2>Freelancer gegevens:</h2>
                    <div>
                        <strong>Kenmerk:</strong> {freelancer.get('id')}<br />
                        <strong>Voorletters:</strong> {freelancer.get('initials')}<br />
                        <strong>Achternaam:</strong> {freelancer.get('lastname')}<br />
                        <strong>Geboortedatum:</strong> {freelancer.get('dateofbirthstring')}<br />
                    </div>
                    <h2>Contact</h2>
                    <div>
                        <ul>
                            {(() => {
                                let contactLists = [];
                                if (freelancer.get('phonenumbers')){
                                    freelancer.get('phonenumbers').forEach((phonenumber) => {
                                        let numberInfo = undefined;
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            numberInfo = ` (${phonenumber.get('type')})`;
                                        }
                                        contactLists.push((
                                            <li key={phonenumber.get('number')}>
                                                <i className="zmdi zmdi-phone"></i>
                                                <a href={"tel:" + phonenumber.get('number')}>{phonenumber.get('number')}{numberInfo}</a>
                                            </li>
                                        ));
                                    });
                                }
                                if (freelancer.get('email')){
                                    contactLists.push((
                                        <li key={freelancer.get('email')}>
                                            <i className="zmdi zmdi-email"></i>
                                            {freelancer.get('email')}
                                        </li>
                                    ));
                                }
                                return contactLists;
                            })()}
                        </ul>
                    </div>
                </div>

                <div className="client-view-content">
                <div className="card">

                    <div className="card-body">


                    <CalendarNavigation />
                    <Calendar 
                            // profileplanning={profileplanning} 
                            onEventSelect={() => {}}
                            events={convertFreelancerPlanningToEvents(profileplanning)}

                        />

                </div>

                </div>
                </div>
            </div>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleFreelancer);

const reasons = {
    "0": "...",
    "2": "Werk",
    "3": "Soc",
    "4": "Hobby",
    "5": "GeNa",
    "6": "Ziek",
    "8": "Vak.",
    "9": "Curs."
}

const colors = {
    "busy-day-1": (op) => `rgba(103,236,0, ${op})`,
    "busy-day-2": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-3": (op) => `rgba(74, 169, 0, ${op})`,
    "busy-day-4": (op) => `rgba(240, 255, 0, ${op})`,
    "busy-day-5": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-6": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-8": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-9": (op) => `rgba(161, 0, 0, ${op})`,
}


function convertFreelancerPlanningToEvents(profileplanning) {

    let events = List();

    const createEventFromRecord = (record, infoRecord, tag = "") => {
        let planningEvents = List();
        let event = Map({ 
            id: tag + infoRecord.get('id').toString(),
            event: infoRecord,
            starttime: record.get('starttime'),
            endtime: record.get('endtime'),
            startTimeIndex: record.get('startTimeIndex'),
            durationIndex: record.get('durationIndex'),
            year: record.get('year'),
            week: record.get('week'),
            dow: record.get('dow')
        })

        let op = infoRecord == record ? 1 : 0.5;

        let color = infoRecord.get("type") ? colors["busy-day-" + infoRecord.get("type").toString()](op) : colors["busy-day-1"](op);
        let text = infoRecord.get("clientid") ? infoRecord.get("clientid") : reasons[infoRecord.get("type", 1).toString()];

        event = event.set('background', color );
        event = event.set('label', text);

        let start = event.get('startTimeIndex');
        let duration = event.get('durationIndex');
        let end = start + duration;
        if(end > 96) {
            let first = event.set('durationIndex', 96 - start);
            let second = event.set('startTimeIndex', 0).set('durationIndex', event.get('durationIndex') - first.get('durationIndex'));
            
            let nextDateInfo = getNextYearWeekDow(event.get('year'), event.get('week'), event.get('dow'));
            second = second.set('year', nextDateInfo.year)
            .set('week', nextDateInfo.week)
            .set('dow', nextDateInfo.dow);
            
            planningEvents = planningEvents.push(first);
            planningEvents = planningEvents.push(second.update('id', x => x + "split" + tag));
        } else {
            planningEvents = planningEvents.push(event);
        }

        return planningEvents;
    }


    profileplanning.forEach((pp) => {
        let convertedEvents = createEventFromRecord(pp, pp);
        events = events.merge(convertedEvents);
        if(pp.getIn(['extra','before'])) {
            let beforeEvent = pp.getIn(['extra','before']);
            let extraStarttime = moment(Number(pp.get('starttime'))).subtract(Number(beforeEvent.get('durationIndex')) * 15, 'minutes').format('x');
            let extraEndtime = pp.get('starttime')
            beforeEvent = beforeEvent.set('starttime', extraStarttime);
            beforeEvent = beforeEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(beforeEvent, pp, 'before');
            events = events.merge(convertedEvents);
        }
        if(pp.getIn(['extra','after'])) {
            let afterEvent = pp.getIn(['extra','after']);
            let extraStarttime = pp.get('endtime');
            let extraEndtime = moment(Number(pp.get('endtime'))).add(Number(afterEvent.get('durationIndex')) * 15, 'minutes').format('x');
            afterEvent = afterEvent.set('starttime', extraStarttime);
            afterEvent = afterEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(afterEvent, pp, 'after');
            events = events.merge(convertedEvents);
        }

    })

    return events;

}

function getNextYearWeekDow(_year, _week, _dow) {
    let date = moment().year(_year).isoWeek(_week).isoWeekday(_dow);
    date.add(1,'d');
    let year = date.year();
    let week = date.isoWeek();
    let dow = date.isoWeekday();
    return {
        year: year,
        week: week,
        dow: dow
    }
}