import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getWhoIsWho } from '../../Actions/Freelancers';
import { getSubjects, getArticles, getAttachments } from '../../Actions/Infotheek';
import {generateDisplayName} from "../../Utils/Person";
import { List } from 'immutable';
import { apiUrl } from '../../Service/EndpointService';
import './Styles/Infotheek.scss';

export default function Infotheek() {

    const dispatch = useDispatch();
    const subjects = useSelector(state => state.getIn(['infotheek','subjects','collection']));
    const subjectsLoading = useSelector(state => state.getIn(['infotheek','subjects','loading']));
    const articles = useSelector(state => state.getIn(['infotheek','articles','collection']));
    const articlesLoading = useSelector(state => state.getIn(['infotheek','articles','loading']));
    const attachments = useSelector(state => state.getIn(['infotheek','attachments','collection']));
    const attachmentsLoading = useSelector(state => state.getIn(['infotheek','attachments','loading']));
    

    useEffect(() => {
        if(!subjects && !subjectsLoading) {
            dispatch(getSubjects());
        }
        if(!articles && !articlesLoading) {
            dispatch(getArticles());
        }
        if(!attachments && !attachmentsLoading) {
            dispatch(getAttachments());
        }
    })

    
    //getWhoIsWho

    return <div className="card">

        <div className="card-header">
            <h1>Infotheek</h1>
        </div>

        <div className="card-body">

            <div className="infotheek">

               
            </div>


        </div>

    </div>

}