import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Components/Home';
import SingleClient from './Components/Clients/SingleClient';
import SingleFreelancer from './Components/Freelancers/SingleFreelancer';
import Clients from './Components/Clients';
import ClientProfileList from "./Components/Clients/ClientProfileList";
import Settings from './Components/Settings';
import WhoIsWho from './Components/WhoIsWho';
import Infotheek from './Components/Infotheek';

class Content extends Component {

    render() {
        return <div className="content">
            <Switch>
                <Route path="/clients/:clientid/:start(\d+)?/:end(\d+)?" component={SingleClient} />
                <Route path="/freelancers/:freelancerid/:start(\d+)?/:end(\d+)?" component={SingleFreelancer} />
                <Route path="/clients" component={Clients} />
                <Route path="/all-clients" component={ClientProfileList} />
                <Route path="/settings" component={Settings} />
                <Route path="/who-is-who" component={WhoIsWho} />
                <Route path="/infotheek" component={Infotheek} />
                <Route component={Home} />
            </Switch>
        </div>
    }
}

export default Content;