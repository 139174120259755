import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OrderedMap, Map, List } from 'immutable';
import { generateFormalDisplayName, generateSemiFormalDisplayName } from '../../Utils/Person';
import { getClients } from '../../Actions/Clients';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import SingeClientPlanning from './SingleClientPlanning';
import './Styles/SingleClient.scss';
import { getPortalAddresses } from '../../Actions/PortalAddresses';
import { setWeekRange } from '../../UIState';
import { NavLink } from 'react-router-dom';
import { TheReadBookButton } from '../TheRedBook';

const apiUrl = window.location.href.match('zorgconnect.dev:30') ? "https://uscportal.zorgconnect.dev" : "";

const mapStateToProps = (state) => ({
    weekRangeStart: state.getIn(['ui','weekRangeStart']),
    weekRangeEnd: state.getIn(['ui','weekRangeEnd']),
    clients: state.getIn(['clients','collection'], OrderedMap()),
    profileplanning: state.getIn(['profileplanning','collection'], OrderedMap()),
    freelancer: state.getIn(['freelancers', 'single'], Map()),
    portalAddresses: state.getIn(['portaladdresses','collection'], List()),
})

const mapDispatchToProps = (dispatch) => ({
    getClients: () => dispatch(getClients()),
    getProfilePlanning: (params) => dispatch(getProfilePlanning(params)),
    setWeekRange: (start, end) => dispatch(setWeekRange(start, end)),
    getPortalAddresses: (clientid) => dispatch(getPortalAddresses(clientid))
})

class SingleClient extends Component {

    componentDidMount() {
        let { getClients, getProfilePlanning } = this.props;
        let { setWeekRange } = this.props;
        // console.log("SingleClient componentDidMount");
        // console.log("props", this.props);

        let { match: { params: { start, end, clientid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        if(
            (start || end) && 
            start != weekRangeStart || end != weekRangeEnd
        ) {
            setWeekRange && setWeekRange(
                Number(start || weekRangeStart), 
                Number(end || weekRangeEnd)
            );
        }
        getClients && getClients();
        getProfilePlanning && getProfilePlanning({
            clientid: clientid
        });
        getPortalAddresses && getPortalAddresses(clientid)

        // console.log("componentDidMount SingleClient", this.props);

    }

    componentDidUpdate(prevProps) {

        let { match: { params: { start, end, clientid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        let { setWeekRange } = this.props;
        let currentStart = start;
        let currentEnd = end;
        let currentWeekRangeStart = weekRangeStart;
        let currentWeekRangeEnd = weekRangeEnd;

        if(prevProps.match.params.clientid != clientid) {

             getPortalAddresses && getPortalAddresses(clientid);
        }

        {
            let { match: { params: { start, end }}, weekRangeStart, weekRangeEnd } = prevProps;
            if(
                (currentStart || currentEnd) &&
                (currentStart != start || currentEnd != end)
                && 
                (currentStart != currentWeekRangeStart || currentEnd != currentWeekRangeEnd)
            ) {
            //     console.log("setting week range start : currentStart",  currentStart);
            // console.log("setting week range start : currentWeekRangeStart", currentWeekRangeStart );
            // console.log("setting week range end : currentEnd",  currentEnd);
            // console.log("setting week range end : currentWeekRangeEnd", currentWeekRangeEnd );
                setWeekRange && setWeekRange(
                    Number(currentStart || currentWeekRangeStart), 
                    Number(currentEnd || currentWeekRangeEnd)
                );
            }
        }
    }

    render() {

        let { portalAddresses, profileplanning, clients, freelancer,  match: { path, params: { clientid, start, end } } } = this.props;

        let client = clients.get(clientid);

        if(!client) {
            return <data />
        }

        console.log("portalAddresses", portalAddresses);

        // console.log("client", client);
        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));
        profileplanning = profileplanning.filter(x => x.get('clientid') == clientid);

        let redbookplanning = List();

        console.log("SingleClient props", this.props);

        return <div className="card single-client">
        
            <div className="card-header">
                <h1>{generateFormalDisplayName(client)}</h1>

            </div>


            <div className="card-body client-view">

                <div className="client-view-side">
                    <h2>Client gegevens:</h2>
                    <div>
                        <strong>Kenmerk:</strong> {client.get('id')}<br />
                        <strong>Voorletters:</strong> {client.get('initials')}<br />
                        <strong>Achternaam:</strong> {client.get('lastname')}<br />
                        <strong>Geboortedatum:</strong> {client.get('dateofbirthstring')}<br />
                        <strong>BSN:</strong> {client.get('bsn')}
                    </div>
                    <h2>Zorgadres:</h2>
                    <div>
                        {client.getIn(['address','street'])} {client.getIn(['address','number'])} {client.getIn(['address','addition'])}<br />
                        {client.getIn(['address','postalcode'])} {client.getIn(['address','location'])}
                    </div>
                    <h2>Contact</h2>
                    <div>
                        <ul>
                            {(() => {
                                let contactLists = [];

                                if (client.get('phonenumbers')){
                                    client.get('phonenumbers').forEach((phonenumber) => {
                                        let numberInfo = undefined;
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            numberInfo = ` (${phonenumber.get('type')})`;
                                        }
                                        contactLists.push((
                                            <li key={phonenumber.get('number')}>
                                                <i className="zmdi zmdi-phone"></i>
                                                <a href={"tel:" + phonenumber.get('number')}>{phonenumber.get('number')}{numberInfo}</a>
                                            </li>
                                        ));
                                    });
                                }
                        
                                if (client.get('email')){
                                    contactLists.push((
                                        <li key={client.get('email')}>
                                            <i className="zmdi zmdi-email"></i>
                                            {client.get('email')}
                                        </li>
                                    ));
                                }
                                return contactLists;
                            })()}
                        </ul>
                    </div>
                    <h2>Teamleden</h2>
                    <div>
                        
                            

                            {portalAddresses.filter(x => x.get('type') == 'freelancer').groupBy(x => x.get('email')).map((group) => {
                                let contactLists = [];

                                let freelancer = group.first();
                                if (freelancer.get('phonenumbers')){
                                    freelancer.get('phonenumbers').forEach((phonenumber) => {
                                        let numberInfo = undefined;
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            numberInfo = ` (${phonenumber.get('type')})`;
                                        }
                                        contactLists.push((
                                            <li key={phonenumber.get('number')}>
                                                <i className="zmdi zmdi-phone"></i>
                                                <a href={"tel:" + phonenumber.get('number')}>{phonenumber.get('number')}{numberInfo}</a>
                                            </li>
                                        ));
                                    });
                                }
                

                                // return ;
                                
                                return <React.Fragment>
                                    <NavLink to={`/freelancers/${freelancer.get('freelancerid')}`}>
                                        <strong>{generateSemiFormalDisplayName(freelancer)}</strong>
                                    </NavLink>
                                    {contactLists.length > 0 && <ul>{contactLists}</ul>} <br />
                                </React.Fragment>

                                

                            }).toList()}
                    </div>
                    <TheReadBookButton profileplanning={redbookplanning} client={client} freelancer={freelancer} />
                    { <React.Fragment>
                        <h2>Zorgplan</h2>
                        <div>
                            <a href={`${apiUrl}/uscportal/files/careplan/${client.get('id')}`} target="_blank" className={"btn btn-primary"}>Downloaden</a>
                        </div>
                    </React.Fragment>}

                </div>

                <div className="client-view-content">
                
                    <SingeClientPlanning client={client} profileplanning={profileplanning} />
            

                </div>


            </div>

            
            

        </div>


    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClient);

